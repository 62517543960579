const { gitInfo = {}, NODE_SERVER_ENV = '', SiteUID = '', WEB_CLIENT } = gbCommonInfo
import schttp from 'public/src/services/schttp'

if (typeof window !== 'undefined') {
  window.uploadCoverage = function (empId) {
    sessionStorage.setItem('empId', empId)
    istanbulAnalytics({ empId })
  }
}

// ignoreVisibilityState设置为true时即使当前tab页面隐藏也上报
export function istanbulAnalytics({ empId = sessionStorage.getItem('empId') || '', callFunc = () => {} } = {}, ignoreVisibilityState) {
  if(window.__coverage__ && gitInfo.branch){
    if (document.visibilityState === 'hidden' && !ignoreVisibilityState) return
    try{
      const jsonFile = new File([JSON.stringify(window.__coverage__)], 'coverage.json', { type: 'text/json' })
      const formData = new FormData()
      formData.append('coverage', jsonFile)
      formData.append('branchName', gitInfo.branch || '') // 分支名称
      formData.append('gitCommitId', gitInfo?.commitId || '') // 打包时的commit id(完整的id，非短名称)
      formData.append('env', NODE_SERVER_ENV) // 当前的环境
      formData.append('platform', 'pc')
      formData.append('site', SiteUID)
      formData.append('empId', empId)
      schttp({
        url: 'https://tep.sheincorp.cn/api/coverage/upload/web',
        method: 'POST',
        data: formData,
        headers: {
          brand: WEB_CLIENT
        },
        timeout: 60000,
      }).then(() => {
        callFunc && callFunc()
      })
    }catch(e){
      // eslint-disable-next-line no-console
      console.log('send coverage fail', e)
    }
  }
  // schttp({
  //   url: '/other/coverage/create',
  // }).then(() => {
  // })
}
