/* global SHEIN_BACKUP_ONETRUST */
import { daEventCenter } from 'public/src/services/eventCenter'
import schttp from 'public/src/services/schttp'
import { isSwitchBffSsr } from '@/public/src/pages/common/bff-new/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { checkStorage } from './utils'

const { CUSTOM_PRIVACY_SUPPORT = [], SiteUID, CUSTOM_CONSENT_SUPPORT = [] } = gbCommonInfo
// 判断当前站点是否需要使用自定义的弹窗 SDK
const isSupportCustomPrivacySDK = () => {
  if (CUSTOM_PRIVACY_SUPPORT.includes(SiteUID)) {
    return true
  }
  return false
}

const EVENT_ID_MAP = {
  expose_privacy_popup: '2-42-1',
  click_privacy_popup: '2-42-2',
  click_privacy_setting: '2-42-3',
  expose_privacy_popup_stay: '2-42-4',
  click_privacy_save_setting: '2-42-5',
  click_privacy_footer_entrance: '2-42-6',
  click_privacy_footer_save: '2-42-7',
  expose_privacy_anomaly_causes: '2-42-8'
}

daEventCenter.addSubscriber({ modulecode: '2-42' })

// 判断当前页面是否需要展示隐私弹窗
const isCurrentPageShouldShowCookieBanner = () => {
  const { page_name } = window.getSaPageInfo || window.SaPageInfo || {}
  const supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']

  if(!page_name)return 'unknown'

  if (supportPage.includes(page_name)) {
    return true
  }
  return false
}

const InitBackUpOnetrustPop = () => {
  import(/* webpackChunkName: "backup-onetrust" */'../onetrust/backup').then(() => {
    SHEIN_BACKUP_ONETRUST.init(() => {
      showPrivacyPop((isSupport)=>{
        if (isSupport) {
          SHEIN_BACKUP_ONETRUST.show()
        } else {
          SHEIN_BACKUP_ONETRUST.hide()
        }
      })
    })
    if(typeof window.privacyCookieSdk !== 'object' || window.privacyCookieSdk === null) window.privacyCookieSdk = {}
    window.privacyCookieSdk.openPreference = SHEIN_BACKUP_ONETRUST.settings
  })
}

// 获取用户当前同意的cookieBanner中的cookie分类,并根据同意的类型更新google收集的数据
const updateGoogleConsentMode = () => {
  if(!window.gtag || !CUSTOM_CONSENT_SUPPORT.includes(SiteUID))return

  const updateObj = {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied'
  }

  const { agreeList = [] } = window.privacyCookieSdk.getCookieCategorySwitchsStatus()

  agreeList.forEach((cookieItem)=>{
    const { categoryId } = cookieItem
    if(categoryId === 'C0002'){
      // Performance Cookies
      updateObj.analytics_storage = 'granted'
    }
    if(categoryId === 'C0004'){
      // Targeting Cookies
      updateObj.ad_storage = 'granted'
      updateObj.ad_user_data = 'granted'
      updateObj.ad_personalization = 'granted'
    }
  })

  localStorage.setItem('gcmStorage', JSON.stringify(updateObj))
  window.gtag('consent', 'update', updateObj)
}

// 获取用户当前同意的cookieBanner中的cookie分类,并根据同意的类型更新bing收集的数据
const updateBingConsentMode = () => {
  if(!window.uetq || !CUSTOM_CONSENT_SUPPORT.includes(SiteUID))return

  const updateObj = {
    ad_storage: 'denied'
  }

  const { agreeList = [] } = window.privacyCookieSdk.getCookieCategorySwitchsStatus()

  agreeList.forEach((cookieItem)=>{
    const { categoryId } = cookieItem
    if(categoryId === 'C0004'){
      // Targeting Cookies
      updateObj.ad_storage = 'granted'
    }
  })

  window.localStorage.setItem('BingConsentMode', updateObj.ad_storage === 'denied' ? 0 : 1)

  typeof window.uetq.push === 'function' && window.uetq.push('consent', 'update', updateObj)
}

//触发所有同意模式代码
const updateConsentMode = () => {
  updateGoogleConsentMode()
  updateBingConsentMode()
}

async function getWebpushDetailSer () {
  let isSubscribedToDevice = false
  const result = await schttp({
    url: '/user-api/subscribe/webpush_detail',
    method: 'POST',
    useBffApi: true
  })
  const { code: detailCode, info } = result
  if(detailCode == 0 && Array.isArray(info)){
    isSubscribedToDevice = info.some((_)=>_.subscribe_status === 2)
    window.localStorage.setItem('subscribePermission', `${new Date().getTime()}:${isSubscribedToDevice ? 1 : 0}`)
  }
  return isSubscribedToDevice ? 1 : 0
}

async function unSubscribeWebpushSer () {
  if (isSwitchBffSsr(['user_marketing_v1']).user_marketing_v1) {
    await schttp({
      url: '/user-api/subscribe/webpush_unsubscribe',
      method: 'POST',
      useBffApi: true
    })
  } else {
    await schttp({
      url: '/api/webpush/unsubscribe/update',
      method: 'POST'
    })
  }
  
  getWebpushDetailSer()
}

const checkWebPushStatus = async () =>{
  const { agreeList = [] } = window.privacyCookieSdk.getCookieCategorySwitchsStatus()

  const subscribeInfo = window.localStorage.getItem('subscribePermission') || ''
  let [cacheTime, isSubscribedToDevice] = subscribeInfo.split(':')
  if(!subscribeInfo || Date.now() - cacheTime > 864000){
    isSubscribedToDevice =  await getWebpushDetailSer()
  }

  const targetAuth = agreeList.some((cookieItem)=>cookieItem.categoryId === 'C0004')

  const webpushDialogResult = window.localStorage.getItem('webpushDialogResult')
  
  if(targetAuth && isSubscribedToDevice != 1 && webpushDialogResult === '0'){
    window.localStorage.removeItem('webpushDialogResult')
  }

  if(!targetAuth){
    UserInfoManager.clearStorage({ key: 'webpushcookie', actionType: 'loadSheinPush' })
  }

  if(isSubscribedToDevice != 1 || targetAuth)return

  unSubscribeWebpushSer()
}

export async function initPrivacyPopDisplay() {
  try {
    if (!isSupportCustomPrivacySDK()) {
      return
    }

    // backup 降级弹窗展示
    if (window.privacyCookieSdkLoadedError) InitBackUpOnetrustPop()
    window.addEventListener('privacyCookieSdkLoadedError', () => InitBackUpOnetrustPop())

    if(typeof window.privacyCookieSdk === 'undefined') return

    const { disagreeList = [] } = window.privacyCookieSdk.getCookieCategorySwitchsStatus()

    checkStorage(disagreeList.map(({ categoryId }) => categoryId))

    window.addEventListener('privacyCookieSdkSubmit', ({ detail = {} }) => {
      updateConsentMode()
      checkWebPushStatus()
      checkStorage(detail?.disagree?.split(',') || [])
    })

    window.addEventListener('privacyCookieSdkTrack', ({detail={}}) => {
      const { event, attrs } = detail
      const id = EVENT_ID_MAP[event]
      if (id) {
        daEventCenter.triggerNotice({
          daId: id,
          extraData: attrs
        })
      }
    })

    window.addEventListener('TPM_SDK_Loaded',  ()=>{
      updateConsentMode()
    })
    
    showPrivacyPop((isSupport)=>{
      if(isSupport && !window.privacyCookieSdkLoadedError){
        window.privacyCookieSdk.showAgreementIfNeed()
      }
    })

  } catch (error) {
    console.error('======弹出兜底弹窗=====', error)
    // 弹出兜底弹窗
    InitBackUpOnetrustPop()
  }
}

function showPrivacyPop(cb, executeCount = 0){
  const result = isCurrentPageShouldShowCookieBanner()

  if(result === 'unknown' || !window.privacyCookieSdk){
    if(executeCount <= 10){
      executeCount +=1
      setTimeout(showPrivacyPop,500,cb,executeCount)
    }else{
      cb(false)
    }
    return
  }

  cb(result)
}