import { windowCacheDeleteCb } from '@shein/common-function'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'

if ('serviceWorker' in navigator && typeof fetch !== 'undefined') {
  onWindowLoad(() => {
    requestUserIdle(async () => {
      const { langPath } = gbCommonInfo
      const swFile = langPath + '/sw-runtime-cache.js'
      try {
        await navigator.serviceWorker.register(swFile)
        window.dispatchEvent(new Event('SW_Loaded'))
        window.SW_Loaded = true
      } catch (error) {
        console.error('ServiceWorker 注册失败。', error)
      }
    })
  })
}

// 页面访问时清除sw缓存
(() => {
    const swCacheClearConfig = {
      '/user/auth/login': ['pcMeHtml']
    }

    Object.keys(swCacheClearConfig).forEach((key) => {
      if(location.pathname.includes(key)) {
        windowCacheDeleteCb({ key: swCacheClearConfig[key] })
      }
    })
})()

onWindowLoad(() =>
  requestIdleCallback(async () => {
    try {
      if (typeof caches !== 'undefined') {
        const inuseNamespace = [
          'addressbook',
          'addressbook-post',
          'campaignsDynamicData',
          'cartDynamicData',
          'dynamicData',
          'homePageDynamicData',
          'payDynamicData',
          'pcCheckoutHtml',
          'pcMeHtml',
        ]
        const keys = await caches.keys()
        await Promise.all(keys.filter(_ => !inuseNamespace.includes(_)).map(useLessKey => caches.delete(useLessKey)))
      }
    } catch (error) {
      console.error(error)
    }

    try {
      if ('serviceWorker' in navigator) {
        const workers = await navigator.serviceWorker.getRegistrations()
        await Promise.all(workers.map(worker => {
          const isOldWebpush = /firebase-messaging-sw/.test(worker.active?.scriptURL)&&!worker.scope.includes('/firebase-cloud-messaging-push-scope')
          const isNeddUnRegister = /sw-runtime-cache-pay-dest|aimtell-worker/.test(worker.active?.scriptURL) || isOldWebpush
          isNeddUnRegister ? worker.unregister() : false
        }))
      }
    } catch (error) {
      console.error(error)
    }
  })
)
