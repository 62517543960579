/**
 * 根据IP设置用户默认货币
 */
import { parseQueryString, getLocalStorage, setLocalStorage } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { reloadByNoCache } from 'public/src/pages/common/utils/index.js'

async function setDefaultCurrency () {
  if (!navigator.cookieEnabled) return
  if (window !== window.top) return
  const defaultCurrencyExpire = getLocalStorage('default_currency_expire')
  const { currency: urlCurrency } = parseQueryString(location.search)
  if (!defaultCurrencyExpire) {
    let res = { code: 0, info: { currency: gbCommonInfo.currencies } }
    
    const { code, info = {} } = res
    if (code != 0) return
    const { currency = {} } = info || {}
    try {
      //const nowCurrency = getCookie('currency') || gbCommonInfo.default_currency || ''
      const nowCurrency = UserInfoManager.get({ key: 'currency' })
      //const defaultCurrency = Object.keys(currency).find(k => currency[k].default == 1)

      localStorage.setItem('currencyData', JSON.stringify(currency))
      setLocalStorage({ key: 'default_currency_expire', value: 1, expire: 1 * 60 * 60 * 1000 }) // 1h

      // url 货币设置为最高级并且当前站点支持该货币
      if (urlCurrency && currency[urlCurrency]) {
        return
      }

      //如果有cdn缓存，cdn缓存的货币与当前货币不一致，清除cdn缓存
      if( gbCommonInfo.currency != nowCurrency ){
        await UserInfoManager.set({ key: 'currency', value: nowCurrency, options: { isLog: 1 } })
        reloadByNoCache()
        return
      }

      //如果当前页面即不是cookie货币，也不是默认货币
      // if( Object.keys(currency).includes(gbCommonInfo.currency) ){
      //   return
      // }
      


      //接口已经会对不匹配货币clearCookie，匹配的话也不需要再重新设置cookie，所以以下逻辑暂时注释

      // 设置的默认货币与接口请求回来的默认不一样，取接口取回来的默认货币
      // if (defaultCurrency && defaultCurrency != nowCurrency) {
      //   const isManualChangeCurrency = getLocalStorage('manualChangeCurrency')
      //   if (!isManualChangeCurrency) { // 手动设置币种还在有效期, 不自动设置币种
      //     setCookie({ key: 'currency', value: defaultCurrency, end: 3600 }) // 1h
      //     window.location.reload(true) // 强制重新刷新页面
      //   }
      // }
    } catch (e) {
      console.log(e)
    }
  }
}

setDefaultCurrency()
