export const BFF_APOLLO_CONFIG = [
  'login_v3',
  'login_v4'
]

export const SSR_BFF_APOLLO_CONFIG = [
  'user_marketing_v1',
  'user_other_v1',
  'user_dsr_v1'
]
