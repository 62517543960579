import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp/index'
import { isSwitchBffSsr } from '@/public/src/pages/common/bff-new/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

const abtResult = {}
async function getLogAbtResults () {
  // return true
  if (abtResult.type == 'on') return true
  // eslint-disable-next-line @shein-aidc/abt/abt
  const result = await abtservice.getUserAbtResult({ posKeys: 'CookieResult' })
  const abtInfo = result['CookieResult']
  // eslint-disable-next-line require-atomic-updates
  abtResult.type = abtInfo.param || 'off'
  return abtResult.type == 'on'
}

export async function sendLogs(params) {
  const abt = await getLogAbtResults()
  if (abt)
    schttp({ method: 'post', url: '/api/user/auth/cookies/get', data: params })
}

export function formatLogParams (v) {
  const all = ['C0001', 'C0002', 'C0003', 'C0004', 'SPD_BG']
  const disagree = []
  const agree = []
  all.forEach((k) => {
    if (v.includes(k)) agree.push(k)
    else disagree.push(k)
  })
  return {
    disagree: disagree.join(','),
    agree: agree.join(','),
    scene: agree.length == all.length ? 2 : 1
  }
}

const generateRequestCacheKey = (url, data = {}) => `PrivacySDK-${url}-${JSON.stringify(data)}`

const setItemWithExpiry = (
  key,
  value,
  expiryInMinutes,
) => {
  const now = new Date()
  const item = {
      value,
      expiry: now.getTime() + expiryInMinutes * 60000, // 过期时间（毫秒）
  }
  localStorage.setItem(key, JSON.stringify(item))
};

const getItemWithExpire = (key) => {
  const localStorageResult = localStorage.getItem(key)
  if (!localStorageResult) return
  try {
      const result = JSON.parse(localStorageResult)
      if (Date.now() < result.expiry) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return result.value
      }
      localStorage.removeItem(key)
  } catch {}
  return undefined
}

export async function getCookieList () {
  const { SiteUID, appLanguage } = window?.gbCommonInfo || {}
  const url = '/user-api/privacy-banner-api/get_private_banner'
  const data = {
    brand: 7,
    domain: window?.location?.hostname,
    siteUid: SiteUID,
    language: appLanguage
  }
  const key = generateRequestCacheKey(url)
  const cache = getItemWithExpire(key,data)
  if(cache)return cache

  const result = await schttp({
    url,
    method: 'POST',
    data,
    useBffApi: true
  })

  setItemWithExpiry(url,result,10)

  return result
}

async function getWebpushDetailSer () {
  let isSubscribedToDevice = false
  const result = await schttp({
    url: '/user-api/subscribe/webpush_detail',
    method: 'POST',
    useBffApi: true
  })
  const { code: detailCode, info } = result
  if(detailCode == 0 && Array.isArray(info)){
    isSubscribedToDevice = info.some((_)=>_.subscribe_status === 2)
    window.localStorage.setItem('subscribePermission', `${new Date().getTime()}:${isSubscribedToDevice ? 1 : 0}`)
  }
  return isSubscribedToDevice ? 1 : 0
}

async function unSubscribeWebpushSer () {
  if (isSwitchBffSsr(['user_marketing_v1']).user_marketing_v1) {
    await schttp({
      url: '/user-api/subscribe/webpush_unsubscribe',
      method: 'POST',
      useBffApi: true
    })
  } else {
    await schttp({
      url: '/api/webpush/unsubscribe/update',
      method: 'POST'
    })
  }

  getWebpushDetailSer()
}

export const checkWebPushStatus = async (agreeStr) =>{
  const subscribeInfo = window.localStorage.getItem('subscribePermission') || ''
  let [cacheTime, isSubscribedToDevice] = subscribeInfo.split(':')
  if(!subscribeInfo || Date.now() - cacheTime > 864000){
    isSubscribedToDevice =  await getWebpushDetailSer()
  }

  const targetAuth = agreeStr.includes('C0004')

  const webpushDialogResult = window.localStorage.getItem('webpushDialogResult')

  if(targetAuth && isSubscribedToDevice != 1 && webpushDialogResult === '0'){
    window.localStorage.removeItem('webpushDialogResult')
  }

  if(!targetAuth){
    UserInfoManager.clearStorage({ key: 'webpushcookie', actionType: 'loadSheinPush' })
  }

  if(isSubscribedToDevice != 1 || targetAuth)return

  unSubscribeWebpushSer()
}

const deleteCookie = (key) => {
  // 获取当前域名的根域名和可能的子域名
  const domainParts = window.location.hostname.split('.')

  // 可能的路径
  const paths = ['/']; // 根据实际情况添加路径

  while (domainParts.length > 0) {
      ['.', ''].forEach(item => {
          const subDomain = `${item}${domainParts.join('.')}` // 遍历所有域名情况
          paths.forEach(path => {
              document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${subDomain}; path=${path};`
          });
      });
      domainParts.shift()
  }
}

const getAllCookieKeys = () => {
  const cookies = document.cookie.split(';')
  const keys = cookies.map(cookie => {
      const [key] = cookie.split('=')
      return key.trim()
  })
  return keys
}

export const checkCookieStatus = async (activeGroup = ['C0001']) => {
const { OPEN_DELETE_COOKIE_API = false, COOKIE_WHITE_LIST = [], COOKIE_BLACK_LIST = {} } = gbCommonInfo
  if(!OPEN_DELETE_COOKIE_API)return
  let categorys = ['h0001','C0001','C0002','C0003','C0004','C0005']
  const cookies = getAllCookieKeys()

  categorys = categorys.filter((category)=>{
    return !activeGroup.includes(category)
  })

  const cookieInfo = await getCookieList()
  const cookieCategoryMap = {
    "C0001":[],
    "C0002":[],
    "C0003":[],
    "C0004":[],
    "C0005":[],
  }

  cookieInfo?.info?.cookieCategorys?.forEach((category)=>{
    const { categoryId, cookies } = category
    if(!cookieCategoryMap[categoryId])return 
    cookieCategoryMap[categoryId] = cookies.map(({key,hostID})=>{
      return {
        Name: key,
        Host: hostID
      }
    })
  })

  const domainData = window.OneTrust?.GetDomainData?.()
  const GroupCookies = domainData?.Groups?.reduce((pre,group)=>{
    const groupId = group?.OptanonGroupId || ''
    if(categorys.includes(groupId)){
      // 植入对应分类的黑名单
      const blackGroup = COOKIE_BLACK_LIST[groupId]
      const cookieCategory = cookieCategoryMap[groupId]

      if(blackGroup?.length){
        pre = [ ...pre, ...blackGroup ]
      }

      if(cookieCategory?.length){
        pre = [ ...pre, ...cookieCategory ]
      }

      pre = [ ...pre, ...group.Cookies]
    }
    return pre
  },[])
  
  const deleteCookieArr = []
  const hostname = '.' + window.location.hostname
  GroupCookies?.forEach((c)=>{
    if(c.Name && !COOKIE_WHITE_LIST.includes(c.Name)){
      const isFirstParty = !c.Host || hostname?.endsWith(c.Host)
      if(isFirstParty){
        deleteCookieArr.push(c.Name)
      }
    }
  })

  if(deleteCookieArr.length && cookies.length){
    cookies.forEach((cookie)=>{
      if(deleteCookieArr.includes(cookie)){
        deleteCookie(cookie)
      }
    })

    await schttp({
      url: '/api/user/auth/cookies/remove',
      method: 'POST',
      data: {
        list: deleteCookieArr
      }
    })
  }
}