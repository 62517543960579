import { setAuthUserInfo } from '@user/login/utils/auth.js'
import { reloadOneTrustBanner, phoneCodeCache } from '@user/login/utils/util.js'
import { getOneTrustToken } from '@user/login/service/fetch.js'

// 字段兼容
const souceFormat = res => {
  if (res?.info?.member?.account_type) {
    res.info.member.account_type = +res.info.member.account_type
    res.info.member.area_code = res?.info?.member?.areaCode || ''
  }
}

export const commonDealWith = async (res, options = {}) => {
  if (res && res.code == 0) {
    souceFormat(res)
    const { type = '', params } = options
    try {
      setAuthUserInfo(res)
      const oneTrustRes = await getOneTrustToken()
      reloadOneTrustBanner(oneTrustRes)
    } catch (error) {
      console.log('onetrust:' + error)
    }
    if (type == 'phone' && params?.area_abbr)
      phoneCodeCache.set(params.area_abbr, 1)
  }
}
