/**
 * 公共模块
 * 关于公共模块的使用说明:
 * 1. 只引入全业务都需要的功能或能力
 * 2. 不要引入仅某业务或某几个业务所需的能力从而导致公共模块过于体积及执行效率下降
 * 3. 全局挂载的全局变量或方法需谨慎考虑是否一定要挂到全局，如果不是必须的，则回归业务本身, 如 SHEIN_W
 */
import { getQueryString, windowCacheDeleteCb } from '@shein/common-function'
import { _sCommonController } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
const { PUBLIC_CDN, LAYOUT } = gbCommonInfo
__webpack_public_path__ = `${PUBLIC_CDN}/she_dist/assets/`

//需要比_sCommonController.registLocals先执行
import './globalCheck'

_sCommonController.registLocals(gbCommonInfo)
import './polyfill'
import './sw'
import './shein_w/core'
import '../../services/TPM'
import './ugidHandle'
import '../../services/schttp/prefetchCsrf'
import { triggerPreFetchProductListPromise } from 'public/src/services/pre_requests/common'
triggerPreFetchProductListPromise()
import './userInfo'
import ADA from './ada'
typeof window !== 'undefined' && new ADA()
import './performance'
import '../../services/abt'
import './devices'
import './infp'
import './performance_safe'
import './point_migrate' // 新站点重开站点积分迁移
import './ip_address_report'

if(gbCommonInfo.headerVueSsr !== true && !gbCommonInfo.noHeader){
  // csr接入方式，执行csr接入代码覆盖ejs模版内容，ssr接入不需要执行这段代码
  import(/* webpackChunkName: "CommonHeader" */ './common-header').then(({ default: initCommonHeader }) => { initCommonHeader() })
}

import(/* webpackChunkName: "CommonFooter" */ './common-footer').then(({ default: initCommonFooter }) => { initCommonFooter() })


import './setStatDynamic'
import '../components/globalPrivacy/asyncIndex'
import './fs_image'
import '../components/login/asyncIndex'
import '../components/signup_fast/asyncIndex'
import { initOneTrusPathDisplay } from './onetrust'
import { initPrivacyPopDisplay } from './privacyPop'
// Webp 大盘的支持情况, 已收集完毕, 不再重复收集
// import './deviceFingerprint/send'
import './tips_mask/loadTipsMark'
// 业务逻辑
import './mdmpUserLanding'
import './analysis'
import './default_currency'
import './init'
import '../components/sidebar/loadSideBar'
import { SILogger, SIMetric } from './monitor'
import './removeCache'
import { loadGlobalPopModule } from './glob_popup_modal'
import './abtPageAll'
import { istanbulAnalytics } from './istanbul-analytics'
import { isLogin, onWindowLoad } from 'public/src/pages/common/utils/index.js'
import { createAppUserActionTracker } from 'public/src/pages/common/userActionTracker/index.js'
createAppUserActionTracker()

onWindowLoad(() => {
  requestAnimationFrame(function () {
    if (LAYOUT === 'layout') {
      import(
        /* webpackChunkName: "pre-online-help-module" */ './pre_online_help'
      )
      // 任务中心浏览倒计时 & 加车进度条新样式
      if (getQueryString({ key: 'actTaskType' })) {
        const actTaskType = getQueryString({ key: 'actTaskType' })
        if (actTaskType == 'browse') {
          // 浏览倒计时
          if (typeof window.gbTaskCenterBrowseTips !== 'undefined') {
            window.gbTaskCenterBrowseTips.init()
          } else {
            import(
              /* webpackChunkName: "TaskCenterBrowse" */ './task-center-tips/browse'
            )
          }
        } else if (actTaskType == 'addCart') {
          // 加车进度条
          if (typeof window.gbTaskCenterAddCartTips !== 'undefined') {
            window.gbTaskCenterAddCartTips.init()
          } else {
            import(
              /* webpackChunkName: "TaskCenterAddCart" */ './task-center-tips/add-cart'
            )
          }
        }
      } else {
        if (typeof window.gbTaskCenterBrowseTips !== 'undefined') {
          window.gbTaskCenterBrowseTips.show = false
          if (window.gbTaskCenterBrowseTips.timer)
            clearInterval(window.gbTaskCenterBrowseTips.timer)
        }
        if (typeof window.gbTaskCenterAddCartTips !== 'undefined') {
          window.gbTaskCenterAddCartTips.show = false
        }
      }
    }

    // 全局弹窗控制入口
    loadGlobalPopModule()

    // 记录访问历史
    void (function visitTrajectory() {
      // trajectory
      if (window.sessionStorage) {
        const sessionVisitTrajectory =
          window.sessionStorage.getItem('visit_trajectory')
        const deep = 5 // 记录深度

        let visit_trajectory = sessionVisitTrajectory
          ? JSON.parse(sessionVisitTrajectory)
          : []
        visit_trajectory.push(window.SaPageInfo?.page_name || '')

        if (visit_trajectory.length > deep) {
          visit_trajectory = visit_trajectory.slice(
            -deep,
            visit_trajectory.length
          )
        }
        window.sessionStorage.setItem(
          'visit_trajectory',
          JSON.stringify(visit_trajectory)
        )
      }
    })()
  })
  window.dispatchEvent(new Event('scroll'))

  // 展示隐私弹窗sdk逻辑
  initPrivacyPopDisplay()

  // 展示onetrust逻辑
  initOneTrusPathDisplay()

  !isLogin() && import('./googleOneTab/index')
})

windowCacheDeleteCb({
  keys: ['cartDynamicData', 'campaignsDynamicData']
})

onWindowLoad(() => {
  if (['gray', 'debug'].includes(gbCommonInfo.NODE_SERVER_ENV)) {
    // lodash和common-function的throttle使用document.visibilityState时并且条件为hidden时无法触发代码
    function throttle(func, timeFrame) {
      let lastTime = 0
      return function () {
        const now = Date.now()
        if (now - lastTime >= timeFrame) {
          func()
          lastTime = now
        }
      }
    }

    document.onvisibilitychange = throttle(() => {
      if(document.visibilityState === 'hidden') {
        istanbulAnalytics(undefined, true)
      }
    }, 30 * 1000)

    const request = indexedDB.open('coverageLib')
    let db
    request.onupgradeneeded = function() {
      // The database did not previously exist, so create object stores and indexes.
      const db = request.result
      db.createObjectStore('coverage', { keyPath: 'key' })
    }

    request.onsuccess = function() {
      db = request.result
      const tx = db.transaction('coverage', 'readwrite')
      const store = tx.objectStore('coverage')
      const objectStoreRequest = store.get('__coverage__')
      objectStoreRequest.onsuccess = () => {
        const res = objectStoreRequest.result
        if(res?.data) {
          const { gitInfo = {}, NODE_SERVER_ENV = '', SiteUID = '' } = gbCommonInfo
          const jsonFile = new File([res.data], 'coverage.json', { type: 'text/json' })
          const formData = new FormData()
          formData.append('coverage', jsonFile)
          formData.append('branchName', gitInfo.branch || '') // 分支名称
          formData.append('gitCommitId', gitInfo?.commitId || '') // 打包时的commit id(完整的id，非短名称)
          formData.append('env', NODE_SERVER_ENV) // 当前的环境
          formData.append('platform', 'pc')
          formData.append('site', SiteUID)
          
          schttp({
            url: 'https://tep.sheincorp.cn/api/coverage/upload/web',
            method: 'POST',
            data: formData,
            headers: {
              brand: WEB_CLIENT
            },
            timeout: 60000,
          }).then(() => {
            const tx = db.transaction('coverage', 'readwrite')
            const store = tx.objectStore('coverage')
            const request = store.delete('__coverage__')
            request.onsuccess = () => {
              console.info('[indexedDB]: __coverage__已删除')
            }
          }).catch(e => {
            console.error('[indexedDB] send coverage fail', e)
          })
        }
      }
    }

    const saveCoverage = () => {
      return new Promise(((resolve, reject)=> {
        const tx = db.transaction('coverage', 'readwrite')
        const objectStoreRequest = tx.objectStore('coverage')
        const request = objectStoreRequest.get('__coverage__')
        request.onsuccess = () => {
          if (request.result) return
          objectStoreRequest.put({ key: '__coverage__', data: JSON.stringify(window.__coverage__), createdAt: Date.now() })
          db.close()
          resolve()
        }
        request.onerror = () => {
          db.close()
          reject()
        }
      }))
    }

    window.addEventListener('beforeunload', async () => {
      if (db) {
        try {
          await saveCoverage()
        } catch (_) {
          console.error('[indexedDB] coverage save error!')
        }
      }
    })
  }
  requestIdleCallback(() => {
    window.TPM?.publish('pageview', {
      page_name: window.SaPageInfo?.page_name || '',
    })
    if (['gray', 'debug'].includes(gbCommonInfo.NODE_SERVER_ENV)) {
      if(navigator.webdriver) return
      setTimeout(() => {
        istanbulAnalytics()
        // 引入手动上报组件
        import(
          'public/src/pages/common/coverageReport/main.js'
        )
      }, 2000)
      setInterval(() => {
        istanbulAnalytics()
      }, 1000 * 60 * 5)
    }
  })

  // 不支持 Proxy 语法的进行埋点上报，用于统计我们站点 Vue 3 访问情况
  if (!window.Proxy) {
    window.sa('send', {
      activity_name: 'expose_browser_not_support_proxy',
    })
  }

  isLogin() && import('./bindMessenger.js')
})

const event = new CustomEvent('monitor-inited', {
  detail: {
    SILogger,
    SIMetric
  }
})
window.dispatchEvent?.(event)
