import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { v4 as uuidv4 } from 'uuid'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager'
import {
  getLocalStorage,
  setLocalStorage
} from '@shein/common-function'

export function getBindCouponPackageParams(couponList = []) {
  const couponPackageGroup = groupBy(couponList, m => m.packageId)
  const couponPackages = map(couponPackageGroup, (v, k) => {
    return {
      couponPackageId: k,
      couponCodes: v.map(m => m.couponCode).join(',')
    }
  })

  return {
    scene: 'home',
    idempotentCode: uuidv4(),
    couponPackages
  }
}

/**
 * 转换 promise 返回结果为错误优先
 * @param {Promise} promise
 * @returns {Promise<[Error, any]>}
 */
export const tryit = function (promise) {
  return promise.then(
    value => [undefined, value],
    err => {
      return [err, undefined]
    }
  )
}

// 判断是否用户首次劵包
export const checkFirstPopup = () => {
  if (isLogin()) {
    const mid =
      UserInfoManager.get({
        key: 'memberId',
        actionType: 'common/coupon_package'
      }) || ''
    return getLocalStorage(`${UserInfoManager.get({ key: 'oest' })}-${mid}`) || '1'
  }
  return '0'
}

export const setFirstPopup = () => {
  const mid =
    UserInfoManager.get({
      key: 'memberId'
    }) || ''

  setLocalStorage({ key: `${UserInfoManager.get({ key: 'oest' })}-${mid}`, value: '1' })
}

export const isEmptyAbtValue = value => !value || value === 'None' || value === 'none'

export function getCouponPackageIds(couponList = []) {
  const set = couponList.reduce((acc, item) => {
    if (item.packageId) {
      acc.add(item.packageId)
    }
    return acc
  }, new Set())

  return Array.from(set).join(',')
}
