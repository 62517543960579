import UserInfoManager from 'public/src/services/UserInfoManager'
import { removeServiceWorkerCache } from './clearSwCache'
import { getLocalStorage, removeLocalStorage } from '@shein/common-function'
import { USERINFO_KEY } from '@user/login/common/contants.js'
import { loginHandlePersonalizedSwitch, logoutHandlePersonalizedSwitch }  from 'public/src/pages/personalized/utils'

export const setAuthUserInfo = (data) => {
  const member = data.info.member || {}
  if (member?.registerTime) {
    member.add_time = member.registerTime
  }
  const ugid = data.info.ugid || ''
  removeServiceWorkerCache()
  UserInfoManager.set({ key: 'UserInfo', value: member, actionType: 'setAuthUserInfo'  })
  UserInfoManager.set({ key: 'ugid', value: ugid, actionType: 'setUgidUserInfo'  })
  window.isLoginSuccessRefresh = true
  // 个性化开关处理
  loginHandlePersonalizedSwitch()
}

export const removeAuthUserInfo = () => {
  try {
    if (getLocalStorage(USERINFO_KEY)) {
      removeLocalStorage(USERINFO_KEY)
    }
    // 清空ugid, 防止二次落地获取的数据为登录的数据
    UserInfoManager.clearStorage({ key: 'ugid' })
    removeServiceWorkerCache()
    // 个性化开关处理
    logoutHandlePersonalizedSwitch()
  } catch (error) {
    console.error( error + 'removeAuthUserInfo error')
  }
}


export const loadRememberModal = async (config) => {
  const tempDiv = document.createElement('div')
  tempDiv.className = 'logout-remember-popup'
  document.body.appendChild(tempDiv)
  const rememberModal = (await import('public/src/pages/components/login/common/logout')).default
  rememberModal?.handleShowRememberModal(config)
}

export const updateLoginPopData = () => {
  const params = {
    isUpdateData: true
  }
  SHEIN_LOGIN && SHEIN_LOGIN.show(params)
}
