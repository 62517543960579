const { FB_APPID, SiteUID } = gbCommonInfo
export class FacebookSDK {
  static name = 'FacebookSDK'

  _domain = '//connect.facebook.net'
  _LOCAL_SDK_URL_MAP = {
    'us': `${this._domain}/en_US/sdk/xfbml.customerchat.js`,
    'ph': `${this._domain}/fil_PH/sdk/xfbml.customerchat.js`,
    'ca': `${this._domain}/en_CA/sdk/xfbml.customerchat.js`,
    'au': `${this._domain}/en_AU/sdk/xfbml.customerchat.js`,
    'us_debug': `${this._domain}/en_US/sdk/debug.js`,
    'ph_debug': `${this._domain}/fil_PH/sdk/debug.js`,
    'ca_debug': `${this._domain}/en_CA/sdk/debug.js`,
    'au_debug': `${this._domain}/en_AU/sdk/debug.js`,
  }
  #INIT_COMMON_PARAMS = {
    appId: FB_APPID,
    status: true,
    cookie: false,
    xfbml: true,
    oauth: true,
    version: 'v17.0',
  }
  _ALREADY_INIT = false

  _getInitFbLogin() {
    return {
      datas: 'facebook-login-datas',
      pixels: [{
        TPM_Ele_Type: 'script',
        src: '//connect.facebook.net/en_US/sdk.js',
        id: 'facebook-login-jssdk',
        onload: () => {
          console.log(`fb-login-sdk script success, window.FB is ${!!window.FB}`)
          if(!this._ALREADY_INIT && window.FB) {
            window.FB.init({ ...this.#INIT_COMMON_PARAMS })
            this._ALREADY_INIT = true
          }
        },
        onerror: () =>{
          console.error('facebook-login-sdk script fail.')
        }
      }]
    }
  }

  _getInitFbMessenger({ pageId, refContent, dialogShowCb, renderCb }) {
    return {
      datas: 'facebook-messenger-datas',
      pixels: [{
        TPM_Ele_Type: 'script',
        src: this._LOCAL_SDK_URL_MAP[SiteUID || 'us'],
        id: 'facebook-messenger-jssdk',
        onload: () => {
          console.log(`fb-messenger-sdk script success, window.FB is ${!!window.FB}`)
          if(!this._ALREADY_INIT) {
            window.FB.init({ ...this.#INIT_COMMON_PARAMS })
            this._ALREADY_INIT = true
          }
          //设置聊天插件的属性
          var chatbox = document.getElementById('fb-customer-chat')
          chatbox?.setAttribute('page_id', pageId)
          // test-pageId
          // chatbox?.setAttribute('page_id', '803124833153844')
          chatbox?.setAttribute('attribution', 'biz_inbox')
          chatbox?.setAttribute('ref', refContent)
          // 订阅 xfbml.render 事件，以在 FB 对话框渲染完成后调用自定义代码
          window.FB?.Event?.subscribe('xfbml.render', function() {
            renderCb?.()
          })
          // watch chat plugin dialogShow event
          window.FB?.Event?.subscribe('customerchat.dialogShow', () => {
            dialogShowCb?.()
          })
        },
        onerror: () =>{
          console.error('facebook-messenger-sdk script fail.')
        }
      }]
    }
  }
}

