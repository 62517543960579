import { removeLocalStorage } from '@shein/common-function'

export function checkStorage(list = [], type = 'disagree'){
    const { NEED_REMOVE_STORAGES = {} } = window.gbCommonInfo

    list = list.filter(v=>!!v).map(categoryId => {
        if(categoryId.startsWith('C000')){
            return categoryId
        }
        return `C000${categoryId - 1}`
    })

    let disagessList = []

    if(type != 'disagree'){
        const allCategoryId = ['C0001','C0002','C0003','C0004','C0005']
        disagessList = allCategoryId.filter(categoryId => !list?.includes(categoryId))
    }else{
        disagessList = list
    }

    const arr = []

    disagessList.forEach((categoryId) => {
        const list = NEED_REMOVE_STORAGES[categoryId]
        if(list?.length){
            arr.push(...list)
        }
    })

    arr.forEach(key => removeLocalStorage(key))
}