
//google自动登录冷却期缓存
export const DISABLE_AUTO_SELECT = 'disableAutoSelect'

//google自动登录冷却时长24小时
export const DISABLE_AUTO_SELECT_TIME = 24 * 3600 * 1000

export const MESSENGER_PAGE_ID = {
  'us': '124739210717984', // 正式的：121041328593190 测试环境：124739210717984
  'ph': '103863481839366',
  'au': '647306098990385',
  'ca': '102143945750425',
}

export const USERINFO_KEY = 'auth_member'

export const KOL_SECRET = 'ajhjkjalh778ah3kjnksSDHJsaAFkjr432jsa' 

// 三方登录字段映射表
export const LOGIN_MAPPING = {
  Facebook: ['fbtoken', 'facebook_id'],
  Google: ['id_token', 'google_id'],
  Vk: ['app_token', 'vk_id'],
  Line: ['id_token', 'user_id'],
  Kakao: ['kakao_token', 'kakao_id'],
  Naver: ['naver_token', 'naver_id'],
}

//三方登录校验合规映射
export const LOGIN_CHECK_MAPPING = {
  Facebook: { idField: 'facebook_id', registerFrom: 11 },
  Google: { idField: 'google_id', registerFrom: 12 },
  Vk: { idField: 'vk_id', registerFrom: 13 },
  Line: { idField: 'user_id', registerFrom: 15 },
  Kakao: { idField: 'kakao_id', registerFrom: 16 },
  Naver: { idField: 'naver_id', registerFrom: 17 },
}


// 三方登录降级映射
export const FUSE_ABT_RESULT_MAPPING = {
  facebook: 'fb',
  google: 'google',
  vk: 'vk',
  line: 'line',
  kakao: 'kakao',
  naver: 'naver'
}

