/**
 * @description 接口请求
 */
import schttp from 'public/src/services/schttp'
import JumpCountry from 'public/src/pages/common/orders/jumpCountry.js'
import { formatSupportsPhoneAreaCodes, formatAllPhoneAreaCodes } from '@user/login/utils/formatUtils.js'
import { commonDealWith } from './utils.js'
import { getBffVersion } from 'public/src/pages/user/bffVersion/index.js'
import { checkRelationAccSer as checkRelationAccSerOld } from '@login/service'

// 获取初始化数据
export const getInitDataSer = async () => {
  const res = await schttp({
    url: '/user/account/init_data',
    useBffApi: true
  })
  return res
}

export const getInitLanguageSer = async () => {
  const langRes = await schttp({
    method: 'POST',
    url: '/system/configs/page_multi_language_mapping',
    data: { pages: ['login'] },
    useBffApi: true
  })
  return langRes
}

// 获取手机国家号区码
export const getPhoneAreaCode = async () => {
  const data = await JumpCountry.getLocationList()
  
  return {
    support: formatSupportsPhoneAreaCodes(data),
    all: data,
    allFormat: formatAllPhoneAreaCodes(data || []),
  }
}

// 获取oneTrust
export const getOneTrustToken = async () => {
  const res = await schttp({
    url: '/user/onetrust_token',
    useBffApi: true
  })
  return res
}

// 账户定位接口
export const aliasLocationSer = async (params) => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/account/positioning',
    data,
    useBffApi: true
  })
  res.tips = res?.msg || ''
  return res
}

// 登录前是否同意合规校验
export const checkClauseSer = async params => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/login/checkClause',
    data,
    useBffApi: true
  })
  return res
}

// 邮箱注册接口
export const emailSignupSer = async (params) => {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const headers = {}
  const res = await schttp({
    method: 'POST',
    url: '/user/email_register',
    headers,
    data,
    useBffApi: true
  })
  res.tips = res?.msg || ''
  await commonDealWith(res)
  return res
}

// 邮箱登录
export const emailLoginSer = async (params) => {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const headers = {}
  const res = await schttp({
    method: 'POST',
    url: '/user/common_login',
    headers,
    data,
    useBffApi: true
  })
  res.tips = res?.msg || ''
  await commonDealWith(res)
  return res
}

// 发送验证码
export const sendCodeSer = async (params) => {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/account/send_sms_code',
    data,
    useBffApi: true
  })
  return res
}

// 发送邮箱验证码
export const sendEmailCodeSer = async (params) => {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/account/send_email_code',
    data,
    useBffApi: true
  })
  return res
}

// 手机号登录
export const phoneLoginSer = async (params) => {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const headers = {}
  const res = await schttp({
    method: 'POST',
    url: '/user/account/phone_login',
    headers,
    data,
    useBffApi: true
  })
  res.tips = res?.msg || ''
  await commonDealWith(res)
  return res
}

// 手机号注册
export const phoneRegesterSer = async (params) => {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const headers = {}
  const res = await schttp({
    method: 'POST',
    url: '/user/account/phone_register',
    headers,
    data,
    useBffApi: true
  })
  res.tips = res?.msg || ''
  await commonDealWith(res)
  return res
}

// 取消订阅
export const unSubscribeSer = async (params) => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/subscribe/unsubscribe',
    data,
    useBffApi: true
  })
  res.tips = res?.msg || ''
  return res
}

// 会员订阅
export const addSubscribeSer = async (params) => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/subscribe/add_subscribe',
    data,
    useBffApi: true
  })
  res.tips = res?.msg || ''
  return res
}

// 邮箱登录绑定手机号
export const phoneBindSer = async (params) => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const headers = {
    'x-ftoken': gbCommonInfo.ftoken
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/account/bind_phone',
    data,
    headers,
    useBffApi: true
  })

  return res
}

// 校验邮箱验证码
export const checkVerifyCodeSer = async params => {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/check_verify_code',
    data,
    useBffApi: true
  })

  return res
}

// 发送邮箱验证链接
export const sendForgotEmailSer = async params => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/forget_password',
    data,
    useBffApi: true
  })

  return res
}

// 重置密码
export const resetPasswordSer = async params => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/account/reset_password',
    data,
    useBffApi: true
  })

  return res
}

// 关联账号校验
export const checkRelationAccSer = async params => {
  if (!getBffVersion('login_v3')) {
    const res = await checkRelationAccSerOld(params)
    return res
  }
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/account/checkRelationAccount',
    data,
    useBffApi: true
  })

  return res
}

// 多账号订阅查询
export const getEmailSubscribeSer = async params => {
  const data = new FormData()
  for (let key in params) {
    data.append(key, params[key])
  }
  const res = await schttp({
    method: 'POST',
    url: '/user/subscribe/subscribed_detail',
    data,
    useBffApi: true
  })

  return res
}

// 多账号登录
export const switchLoginSer = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/user-api/account/switch_login',
    data,
    useBffApi: true
  })
  await commonDealWith(res)
  return res
}

// 删除多账号列表
export const removeAccountSer = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/user-api/account/remove_history',
    data,
    useBffApi: true
  })

  return res
}
