import schttp from 'public/src/services/schttp'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
// import { getCookie } from '@shein/common-function'

let isFlag
class FetchPageAbt {
  constructor() {
    this.hasFetchPage = false       // 进入页面触发一次 过后不在触发。  登录和注册重新触发一次
    this.time = 6 * 1000 * 60 * 60  // 6小时发送一次
    this.init()
  }

  init() {
    this._clear()
    this.validate()
    this._request()
  }

  validate() {
    if (!UserInfoManager.get({ key: 'oest' })) {
      this.hasFetchPage = true // 没有cookieId 不发送
    }
  }

  _getLocalKey() {
    const userEamil = UserInfoManager.get({ key: 'memberId', actionType: 'abt/all' })
    return `abt-page-${userEamil || 'notLogin'}`
  }

  _clear() {
    this.hasFetchPage = false
  }

  _updateState() {
    if (this.hasFetchPage) return
    try {
      const k = this._getLocalKey()
      const data = JSON.parse(localStorage.getItem(k || '{}'))
      if (data?.expireTime) {
        if (Date.now() < data?.expireTime) {
          this.hasFetchPage = true  // 已经触发过
        }
      }
    } catch(e) {
      // console.log(e)
    }
  }

  // 全局分流日志，和业务无关。  只发送请求即可。 6小时发送一次
  async _request() {
    this._updateState()
    if (this.hasFetchPage) return 

    const { code } = await schttp({
      url: '/api/abt/report',
      method: 'post',
      params: {},
    })
    if (code == 200) {
      this.hasFetchPage = true
      const k = this._getLocalKey()
      localStorage.setItem(k, JSON.stringify({
        expireTime: Date.now() + this.time
      }))
    }
  }
}

requestUserIdle(() => {
  if (isFlag) {
    return
  }
  new FetchPageAbt()
  isFlag = true
})

